import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Typography } from '@mui/material';
import React from 'react';
import { useCSVReader } from 'react-papaparse';
import { observer } from 'mobx-react-lite';

import { COLOR_PRIMARY, COLOR_WHITE } from '../../../constants/colors';
import { Roles } from '../../../constants/userRoles';

import { useStore } from '../../../hooks/useStore';
import FlexRowCenter from '../../utils/flex/FlexRowCenter';

interface FormProps {
  email: string;
  role: string;
  teams: string[];
}
interface Props {
  setUsersList: (arg1: FormProps[]) => void;
  usersList: FormProps[];
  setLoading: (a1: boolean) => void;
  setTeamsErrors: (a: string[]) => void;
  setSaveErrors: (a: string[]) => void;
  saveErrors: string[];
}

const ImportCSV = ({ setLoading, setSaveErrors, saveErrors, setTeamsErrors, setUsersList, usersList }: Props) => {
  const {
    localizationStore: { i18next: i18n },
    teamStore: { teams },
  } = useStore();
  const { CSVReader } = useCSVReader();

  const getRole = (role: string) => {
    if (role.toLowerCase() === 'member') {
      return Roles.member;
    } else if (role.toLowerCase().includes('org')) {
      return Roles.organizationAdmin;
    } else if (role.toLowerCase().includes('admin')) {
      return Roles.admin;
    }
  };

  const uploadCSVDataAsUsers = (results: any) => {
    setLoading(true);
    setSaveErrors([]);
    let userData;
    if (results?.data?.length) {
      const data = results?.data;
      const errors = saveErrors;
      userData = data
        ?.filter((item: string[]) => item?.length && !item.includes('Email'))
        ?.map((item: string[]) => {
          const userTeams = (item?.length && item[3] && item[3].split(', ')) || [];
          userTeams?.map(userTeam => {
            if (!teams?.find(team => team.name === userTeam)) {
              errors.push(
                `A team with the name "${userTeam}" does not exist in database, so it will not be added for ${item[1]}.`
              );
            }
            return item;
          });
          setTeamsErrors(errors);
          return {
            fullName: item[0],
            email: item[1],
            role: getRole(item[2]),
            teams: userTeams,
          };
        });
    }
    if (userData?.length) {
      setUsersList([...usersList, ...userData]);
    }
    setLoading(false);
  };

  return (
    <CSVReader onUploadAccepted={uploadCSVDataAsUsers}>
      {({ getRootProps }: any) => (
        <>
          <button type="button" {...getRootProps()}>
            <FlexRowCenter sx={{ gap: '8px', cursor: 'pointer', background: COLOR_WHITE }}>
              <FileUploadIcon sx={{ width: '16px', height: '16px', color: COLOR_PRIMARY }} />
              <Typography variant={'subtitle2'} sx={{ color: COLOR_PRIMARY, fontWeight: 500 }}>
                {i18n.t('common.importCSV')}
              </Typography>
            </FlexRowCenter>
          </button>
        </>
      )}
    </CSVReader>
  );
};

export default observer(ImportCSV);
