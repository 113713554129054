import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import { observer } from 'mobx-react';

import { GRAY_COLORS } from '../../../../constants/colors';

import Flex from '../../../../components/utils/flex/Flex';
import { Headline3 } from '../../../../components/typography/Headlines';
import { CustomSearchBar } from '../../../../components/CustomSearchBar/CustomSearchBar';
import { SupportedAIProvidersOptions } from '../../../../constants/supportedAIProvidersEnum';
import SupportedAiProviderLogo from '../../../../components/SupportedProviderLogo/SupportedAiProviderLogo';
import { getTextOverflowEllipsisStyles } from '../../../../components/typography/utils';
import AppTable from '../../../../components/table/AppTable';
import ModelTableRow from '../../../../components/tableRows/ModelTableRow';
import { LoadingSpinner } from '../../../../components/spinner/LoadingSpinner';
import { useStore } from '../../../../hooks/useStore';
import { OrganizationModel } from '../../../../models/OrganizationModel';
import { filterObjectsByKeys } from '../../../../utils/filterObjectsByKeys';

type ModelsTableProps = {
  openEditScreen: () => void;
  setIsDirtyState: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAddDefaultModelModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderText: React.Dispatch<React.SetStateAction<string>>;
};

const ModelsTable = ({
  openEditScreen,
  setIsAddDefaultModelModalOpen,
  setHeaderText,
  setIsDirtyState,
}: ModelsTableProps) => {
  const {
    modelStore: { findAllOrganizationModels, organizationModels },
    localizationStore: { i18next: i18n },
  } = useStore();

  const [pageLoading, setPageLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  const [filteredModels, setFilteredModels] = useState(organizationModels);

  useEffect(() => {
    (async () => {
      setPageLoading(true);
      await findAllOrganizationModels();
      setPageLoading(false);
    })();
  }, []);

  useEffect(() => {}, [searchValue]);

  useEffect(() => {
    if (!searchValue) {
      setFilteredModels(organizationModels);
    }

    const newFilteredModels = filterObjectsByKeys(
      organizationModels,
      ['displayName', 'modelVersion'],
      searchValue
    ).filter((item: OrganizationModel) => {
      return !(selectedFilter !== 'all' && item.providerName !== selectedFilter);
    });

    setFilteredModels(newFilteredModels);
  }, [searchValue, selectedFilter, organizationModels]);

  const tableHeaderColumnNames = [
    {
      name: i18n.t('models.tableHeader.modelName.label'),
      width: 20,
    },
    {
      name: i18n.t('models.tableHeader.source.label'),
      width: 5,
    },
    {
      name: i18n.t('models.tableHeader.description.label'),
      width: 30,
    },
    {
      name: i18n.t('models.tableHeader.isDefault.label'),
      width: 8,
    },
    {
      name: i18n.t('models.tableHeader.isActive.label'),
      width: 8,
    },
    {
      name: i18n.t('models.tableHeader.actions.label'),
      width: 8,
    },
  ];

  if (pageLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Box>
          <Headline3>{i18n.t('adminPortal.tabs.models.header')}</Headline3>
          <Typography variant={'body2'} sx={{ marginTop: '8px', marginBottom: '24px', color: GRAY_COLORS.GRAY_7 }}>
            {i18n.t('adminPortal.tabs.models.subtitle')}
          </Typography>
        </Box>
      </Flex>

      <Flex>
        <CustomSearchBar
          onCancelSearch={() => setSearchValue('')}
          onChange={setSearchValue}
          sx={{ flex: 6, marginRight: '20px', marginBottom: '16px' }}
          placeholder={'Search Model'}
        />

        <TextField
          sx={{ flex: 2 }}
          id="filter-by-role"
          value={selectedFilter}
          select
          onChange={(event: any) => setSelectedFilter(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ width: '100px', marginRight: '6px' }} position="start">
                Provider:
              </InputAdornment>
            ),
          }}
        >
          {[
            {
              id: 'all',
              name: 'All',
            },
            ...SupportedAIProvidersOptions,
          ]?.map((item, index) => (
            <MenuItem value={item.id} key={`filter-by-role-${index}`}>
              <Flex sx={{}}>
                {item.id !== 'all' && (
                  <SupportedAiProviderLogo provider={item.id} size={16} style={{ marginRight: '6px' }} />
                )}
                <Typography variant={'subtitle2'} sx={getTextOverflowEllipsisStyles(1)}>
                  {i18n.t(item.name)}
                </Typography>
              </Flex>
            </MenuItem>
          ))}
        </TextField>
      </Flex>

      <AppTable style={{ flex: 1, overflow: 'scroll' }} noBorder tableHeaderColumnNames={tableHeaderColumnNames}>
        {filteredModels.map((model, index) => {
          return (
            <ModelTableRow
              model={model}
              index={index}
              setIsDirtyState={setIsDirtyState}
              openAddDefaultModelModal={headerTextValue => {
                setHeaderText(headerTextValue);
                setIsAddDefaultModelModalOpen(true);
              }}
              openEditScreen={openEditScreen}
            />
          );
        })}
      </AppTable>
    </>
  );
};

export default observer(ModelsTable);
