import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import IconButton from '@mui/material/IconButton';

import { GRAY_COLORS } from '../../../../../constants/colors';

import Flex from '../../../../utils/flex/Flex';
import { User } from '../../../../../models/User';
import UserAvatar from '../../../../UserAvatar/UserAvatar';

interface TeamUserRowProps {
  teamUser: User;
  onRemoveClick?: (user: User) => void;
}

const TeamUserRow = ({ teamUser, onRemoveClick }: TeamUserRowProps) => {
  const { fullName, position, email } = teamUser;

  return (
    <Flex sx={{ width: '100%', gap: '16px', marginTop: '24px' }}>
      <UserAvatar user={teamUser} size={40} />
      <Stack>
        <Typography variant={'body2'}>{fullName || email}</Typography>
        <Typography variant={'body2'} sx={{ fontSize: '12px', color: GRAY_COLORS.GRAY_500 }}>
          {position}
        </Typography>
      </Stack>
      {onRemoveClick && (
        <IconButton sx={{ marginLeft: 'auto' }} onClick={() => onRemoveClick(teamUser)}>
          <RemoveCircleOutlineOutlinedIcon />
        </IconButton>
      )}
    </Flex>
  );
};

export default TeamUserRow;
