import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import { ModelApi } from '../api/ModelApi';
import { EMPTY_ORGANIZATION_MODEL, OrganizationModel } from '../models/OrganizationModel';
import { Model } from '../models/Model';

export class ModelsStore {
  modelApi: ModelApi;
  currentOrganizationModel: OrganizationModel = EMPTY_ORGANIZATION_MODEL;

  organizationModels: Array<OrganizationModel> = [];
  defaultOrganizationModel: OrganizationModel = EMPTY_ORGANIZATION_MODEL;
  supportedModels: Array<Model> = [];

  constructor(modelApi: ModelApi) {
    this.modelApi = modelApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  async findAllOrganizationModels() {
    const organizationModels = await this.modelApi.findAll();
    let defaultModel: OrganizationModel;

    organizationModels.forEach(model => {
      if (!model.isDefault) {
        return;
      }

      defaultModel = model;
    });

    runInAction(() => {
      this.organizationModels = organizationModels;

      if (defaultModel) {
        this.defaultOrganizationModel = defaultModel;
      }
    });

    return organizationModels;
  }

  async updateAllOrganizationModels() {
    const mappedModels = this.organizationModels.map(model => {
      return {
        id: model.id,
        isActive: model.isActive,
        isDefault: model.isDefault,
      };
    });

    await this.modelApi.updateAll(mappedModels);
  }

  async removeOne(modelId: string) {
    await this.modelApi.deleteOne(modelId);

    runInAction(() => {
      this.organizationModels = this.organizationModels.filter(model => model.id !== modelId);
    });
  }

  async findSupported() {
    const supportedModels = await this.modelApi.findSupported();

    runInAction(() => {
      this.supportedModels = supportedModels;
    });

    return supportedModels;
  }

  setCurrentOrganizationModel(model: OrganizationModel) {
    runInAction(() => {
      this.currentOrganizationModel = model;
    });
  }

  async updateOrganizationModel(updateModelDto: OrganizationModel) {
    const updatedModel = await this.modelApi.updateOne(updateModelDto);

    const indexOfModelToBeUpdated = this.organizationModels.findIndex(
      organizationModel => organizationModel.id === updatedModel.id
    );

    runInAction(() => {
      if (indexOfModelToBeUpdated < 0) {
        return;
      }

      const newOrganizationModels = [...this.organizationModels];
      newOrganizationModels[indexOfModelToBeUpdated] = updatedModel;

      if (updatedModel.isDefault) {
        const indexOfOldDefaultModel = this.organizationModels.findIndex(
          organizationModel => organizationModel.id === this.defaultOrganizationModel.id
        );

        if (indexOfOldDefaultModel > -1) {
          newOrganizationModels[indexOfOldDefaultModel].isDefault = false;
        }

        this.defaultOrganizationModel = updatedModel;
      }

      this.organizationModels = newOrganizationModels;
    });
  }
}
