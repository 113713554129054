import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import ExpandableRow from '../../components/adminPortal/compliance/ExpandableRow';
import UnsaveChangesModal from '../../components/adminPortal/compliance/UnsavedChangesModal';
import Button from '../../components/buttons/Button';

import GenericCard from '../../components/cards/GenericCard';
import AppTable from '../../components/table/AppTable';
import { Headline3 } from '../../components/typography/Headlines';
import FlexColumnStart from '../../components/utils/flex/FlexColumnStart';
import FlexRowSpaceBetween from '../../components/utils/flex/FlexRowSpaceBetween';

import { Paths } from '../../constants/routes';
import { useStore } from '../../hooks/useStore';
import { ComplianceModel, INITIAL_COMPLIANCE, UpdateComplianceRuleDto } from '../../models/ComplianceModel';

const CompliancePage = ({ activeTab, setActiveTab }: { setActiveTab: (arg: number) => void; activeTab: number }) => {
  const {
    localizationStore: { i18next: i18n },
    complianceStore: { getCompliances, compliances, updateCompliances, setCompliances },
  } = useStore();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [initialCompliances, setInitialCompliances] = useState<ComplianceModel[] | []>([]);

  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  useEffect(() => {
    (async () => {
      const initial = await getCompliances();
      setInitialCompliances(initial);
    })();

    return () => {
      handleDisplayModal();
    };
  }, []);

  useEffect(() => {
    if (activeTab !== 5) {
      handleDisplayModal();
    }
    setSaveButtonDisabled(true);
  }, [activeTab]);

  const handleDisplayModal = () => {
    compliances?.forEach((compliance, index) => {
      const rules = getRulesObject(compliance);
      const newRules: UpdateComplianceRuleDto[] = getRulesObject(initialCompliances[index]);
      const diff = _.differenceWith(rules, newRules, _.isEqual);

      if (rules?.length === newRules?.length && diff?.length) {
        setShowUnsavedModal(true);
        return;
      }
    });
  };

  const getRulesObject = (entity: ComplianceModel) => {
    const newRules: UpdateComplianceRuleDto[] = entity?.rules?.map(item => ({
      id: item.id,
      isActive: item.isActive,
      restrictionLevel: item.restrictionLevel || (item.isActive ? 'blocked' : undefined),
      anonymizeText: item.anonymizeText || '',
    }));
    return newRules;
  };

  const onSave = async () => {
    const newRules: UpdateComplianceRuleDto[] = [];
    compliances?.forEach(compliance => {
      const rules = getRulesObject(compliance);
      newRules.push(...rules);
    });

    try {
      const compliances = await updateCompliances(newRules);
      setInitialCompliances(compliances);

      setShowUnsavedModal(false);
      addToast(i18n.t('adminPortal.compliance.save.successful'), { appearance: 'success' });

      setSaveButtonDisabled(true);
    } catch (e) {
      addToast(i18n.t('common.somethingWentWrong'), { appearance: 'error' });
    }
  };

  const updateCompliancesRules = (entity: ComplianceModel) => {
    const newCompliances = compliances;
    const updatedIndex = newCompliances.findIndex(item => item.id === entity.id);
    newCompliances[updatedIndex] = entity;

    saveButtonDisabled && setSaveButtonDisabled(false);
  };

  const onClose = () => {
    setShowUnsavedModal(false);
    navigate(Paths.ADMIN_PORTAL);
    setActiveTab(5);
  };

  const onDismiss = () => {
    setShowUnsavedModal(false);
    setSaveButtonDisabled(false);
    setCompliances(initialCompliances);
  };

  return (
    <FlexColumnStart>
      <GenericCard
        disableHover
        height={'fit-content'}
        sx={{
          marginBottom: '24px',
        }}
      >
        <FlexRowSpaceBetween sx={{ marginBottom: '24px' }}>
          <Headline3>{i18n.t('adminPortal.compliance.title')}</Headline3>
          <Button
            disabled={saveButtonDisabled}
            onClick={onSave}
            sx={{
              padding: '9px 16px',
              height: '40px !important',
              width: 'fit-content',
              fontWeight: 400,
              marginLeft: '12px',
            }}
          >
            {i18n.t('common.saveChanges.action')}
          </Button>
        </FlexRowSpaceBetween>
        <FlexColumnStart>
          {compliances?.map((compliance, index) => (
            <AppTable
              key={`compliance-${index}`}
              style={{
                height: 'calc(100% - 56px)',
                overflow: 'scroll',
                minHeight: 0,
                marginBottom: '16px',
                borderRadius: '8px',
              }}
            >
              <ExpandableRow
                entity={compliance}
                setEntity={entity => {
                  updateCompliancesRules(entity);
                }}
              />
            </AppTable>
          ))}
        </FlexColumnStart>
      </GenericCard>
      <UnsaveChangesModal
        isOpen={showUnsavedModal}
        onSave={onSave}
        onClose={onClose}
        onDismiss={onDismiss}
        sx={{ width: '450px' }}
      />
    </FlexColumnStart>
  );
};

export default observer(CompliancePage);
