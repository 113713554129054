import React from 'react';
import { Typography } from '@mui/material';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import IconButton from '@mui/material/IconButton';

import { GRAY_COLORS } from '../../../constants/colors';

import FlexRowSpaceBetween from '../../utils/flex/FlexRowSpaceBetween';
import FlexRowCenter from '../../utils/flex/FlexRowCenter';
import SelectRoleAndTeams from './SelectRoleAndTeams';
import { Team } from '../../../models/Team';

interface FormProps {
  email: string;
  role: string;
  teams: string[];
}
interface Props {
  setUsersList: (arg1: FormProps[]) => void;
  teamFilters: Team[];
  usersList: FormProps[];
}
const InviteUserRow = ({ usersList, setUsersList, teamFilters }: Props) => {
  const onChangeRole = (item: FormProps, value: string) => {
    const newUsersList = usersList?.map(user => {
      if (user.email === item.email) {
        user.role = value;
      }
      return user;
    });
    setUsersList(newUsersList);
  };

  const setSelectedTeams = (item: FormProps, value: string[]) => {
    const newUsersList = usersList?.map(user => {
      if (user.email === item.email) {
        user.teams = value;
      }
      return user;
    });
    setUsersList(newUsersList);
  };

  const onRemoveUser = (item: FormProps) => {
    const newUsersList = usersList?.filter(user => user.email !== item.email);
    setUsersList(newUsersList);
  };

  return (
    <>
      {usersList?.map((item, index) => (
        <FlexRowSpaceBetween sx={{ width: '100%', marginTop: '16px' }} key={`user-row-${index}`}>
          <Typography
            variant={'subtitle2'}
            color={GRAY_COLORS.GRAY_700}
            sx={{ whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {item.email}
          </Typography>

          <FlexRowCenter>
            <SelectRoleAndTeams
              onChangeRole={value => onChangeRole(item, value)}
              selectedRole={item.role}
              selectedTeams={item.teams}
              setSelectedTeams={value => setSelectedTeams(item, value)}
              teamFilters={teamFilters}
              backgroundOnActive={true}
            />
            <IconButton onClick={() => onRemoveUser(item)}>
              <RemoveCircleOutlineOutlinedIcon sx={{ width: '14px', height: '14px' }} />
            </IconButton>
          </FlexRowCenter>
        </FlexRowSpaceBetween>
      ))}
    </>
  );
};

export default InviteUserRow;
