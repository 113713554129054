import { Box, Grid, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import CreateTeamModal from '../../components/adminPortal/teams/CreateTeamModal';
import CreateTeamCard from '../../components/adminPortal/teams/teamCards/CreateTeamCard';
import TeamCard from '../../components/adminPortal/teams/teamCards/TeamCard';
import TeamDetailsCard from '../../components/adminPortal/teams/teamCards/TeamDetailsCard';
import { CustomSearchBar } from '../../components/CustomSearchBar/CustomSearchBar';
import { LoadingSpinner } from '../../components/spinner/LoadingSpinner';
import Flex from '../../components/utils/flex/Flex';

import { COLOR_WHITE } from '../../constants/colors';
import { LocalStorageKeys } from '../../constants/localStorageKeys';
import { SortOptionsTeams } from '../../constants/sortOptions';

import { useStore } from '../../hooks/useStore';
import { INITIAL_TEAM, Team } from '../../models/Team';
import { SortTeamsFormulas } from '../../utils/sortTeams';

const PageContainer = styled(Box)`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  gap: 16px;
`;

const TeamsPageContainer = styled(Stack)`
  border-radius: 8px;
  background-color: ${COLOR_WHITE};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  height: 100%;
  overflow: auto;
`;

const CardsContainer = styled(Grid)`
  padding: 24px;
`;

const TeamsPage = ({ activeTab, changeTab }: { activeTab: number; changeTab: Function }) => {
  const {
    localizationStore: { i18next: i18n },
    teamStore: { teams, getTeamsByUserOrganization, selectedTeam },
  } = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const [showCreateTeam, setShowCreateTeam] = useState(false);
  const [isEditTeamModalOpen, setIsEditTeamModalOpen] = useState(false);

  const [teamToEdit, setTeamToEdit] = useState(INITIAL_TEAM);
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState(SortOptionsTeams.fromAtoZ);
  const [filteredTeams, setFilteredTeams] = useState(teams);

  useEffect(() => {
    (async () => {
      if (activeTab === 1) {
        await getTeamsByUserOrganization();
        setIsLoading(false);
      }
    })();
  }, [activeTab]);

  useEffect(() => {
    const searchValue = search.trim().toLowerCase();
    const sortFunction = SortTeamsFormulas[sortOrder];

    if (searchValue) {
      setFilteredTeams(sortFunction(teams.filter(team => team.name.toLowerCase().includes(search))));
      return;
    }

    setFilteredTeams(sortFunction(teams));
  }, [search, teams, sortOrder]);

  const onCreateTeamCloseModal = () => {
    setShowCreateTeam(false);
  };

  const onEditTeamCloseModal = () => {
    setIsEditTeamModalOpen(false);
  };

  const onCreateTeamOpenModal = () => {
    setShowCreateTeam(true);
  };

  const onEditTeamOpenModal = () => {
    setIsEditTeamModalOpen(true);
  };

  const onTeamAuditLogNavigate = (team: Team) => {
    localStorage.setItem(LocalStorageKeys.auditLogTeamName, team.name);

    changeTab(null, 3);
  };

  if (isLoading) {
    return <LoadingSpinner contained />;
  }

  return (
    <PageContainer>
      <TeamsPageContainer sx={{ width: selectedTeam.id ? '60%' : '100%' }}>
        <Flex
          sx={{
            gap: '8px',
            width: '100%',
            justifyContent: 'space-between',
            position: 'sticky',
            top: 0,
            backgroundColor: COLOR_WHITE,
            paddingTop: '24px',
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingBottom: '8px',
            zIndex: 100,
          }}
        >
          <Typography variant={'h5'} sx={{ fontSize: '18px' }}>
            {i18n.t('labels.teams')}
          </Typography>
          <Flex sx={{ gap: '8px' }}>
            <CustomSearchBar
              onChange={setSearch}
              onCancelSearch={() => setSearch('')}
              sx={{ width: 'fit-content' }}
              placeholder={i18n.t('common.search.message')}
            />
            <TextField
              id="sort-teams"
              value={sortOrder}
              select
              onChange={(event: any) => setSortOrder(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ width: '100px', marginRight: '14px' }} position="start">
                    Sort:
                  </InputAdornment>
                ),
              }}
            >
              {[
                SortOptionsTeams.fromAtoZ,
                SortOptionsTeams.fromZtoA,
                SortOptionsTeams.mostToLeastActive,
                SortOptionsTeams.leastToMostActive,
              ]?.map((item, index) => (
                <MenuItem value={item} key={`filter-by-role-${index}`}>
                  <Typography variant={'subtitle2'}>{i18n.t(`teamsList.sort.${item}`)}</Typography>
                </MenuItem>
              ))}
            </TextField>
          </Flex>
        </Flex>
        <CardsContainer container spacing={'16px'}>
          <Grid item xs={selectedTeam.id ? 6 : 3}>
            <CreateTeamCard onClick={onCreateTeamOpenModal} />
          </Grid>
          {filteredTeams.map((team, index) => (
            <Grid item xs={selectedTeam.id ? 6 : 3} key={`card-team-${index}`}>
              <TeamCard team={team} setTeamToEdit={setTeamToEdit} showEditTeamModal={onEditTeamOpenModal} />
            </Grid>
          ))}
        </CardsContainer>
        <CreateTeamModal isOpen={showCreateTeam} onClose={onCreateTeamCloseModal} />
        <CreateTeamModal isOpen={isEditTeamModalOpen} isEdit team={teamToEdit} onClose={onEditTeamCloseModal} />
      </TeamsPageContainer>
      {selectedTeam.id && (
        <TeamsPageContainer sx={{ width: '40%' }}>
          <TeamDetailsCard onTeamAuditLogNavigate={onTeamAuditLogNavigate} />
        </TeamsPageContainer>
      )}
    </PageContainer>
  );
};

export default observer(TeamsPage);
