import { Organization } from './Organization';

export interface OrganizationModel {
  id: string;
  organizationId: string;
  modelId: string;
  providerName: string;
  modelVersion: string;
  displayName: string;
  description: string;
  apiKey: string;
  apiEndpoint?: string;
  logoPicture?: string;
  logoPictureUrlExpiration?: Date;
  logoPictureFileKey?: string;
  type: string;
  maxRequestsPerHour: number;
  maxTokens?: number;
  maxTemperature?: number;
  supportsText: boolean;
  supportsImage: boolean;
  isActive: boolean;
  isDefault: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  organization?: Organization;
}

export const EMPTY_ORGANIZATION_MODEL = {
  apiKey: '',
  apiEndpoint: '',
  logoPicture: '',
  description: '',
  displayName: '',
  id: '',
  isActive: false,
  isDefault: false,
  maxRequestsPerHour: 0,
  modelId: '',
  modelVersion: '',
  organizationId: '',
  providerName: '',
  supportsImage: false,
  supportsText: false,
  type: '',
};
