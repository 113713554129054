import { Close, FolderOutlined, People } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Joi from 'joi';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { COLOR_PRIMARY, GRAY_COLORS } from '../../../constants/colors';
import { useStore } from '../../../hooks/useStore';
import { Team } from '../../../models/Team';
import { User } from '../../../models/User';

import Button from '../../buttons/Button';
import Divider from '../../Divider/Divider';
import TextInput from '../../inputs/TextInput';
import CustomModal from '../../modal/CustomModal';
import UserAutocomplete from '../../userAutocomplete/UserAutocomplete';
import Flex from '../../utils/flex/Flex';
import TeamUserRow from './teamCards/teamUserRow/TeamUserRow';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  isEdit?: boolean;
  team?: Team;
}

interface FormProps {
  name: string;
}

const CreateTeamModal = ({ isOpen, onClose, isEdit, team }: ModalProps) => {
  const {
    localizationStore: { i18next: i18n },
    teamStore: { createTeam, updateTeam, getTeamsByUserOrganization },
    userStore: { users },
  } = useStore();

  const [formData, setFormData] = useState({
    name: isEdit ? team?.name || '' : '',
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
  });
  const [isDirtyState, setIsDirtyState] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setFormData({ name: '' });
      setListOfUsers([]);
      return;
    }

    setFormData({ name: team?.name || '' });
    setListOfUsers(team?.users || []);

    return () => {
      setLoading(false);
      setIsDirtyState(false);
      setFormErrors({
        name: '',
      });
    };
  }, [team?.name, team?.users, isOpen]);

  const [listOfUsers, setListOfUsers] = useState<any[]>(isEdit ? team?.users || [] : []);

  const getSchema = (i18n: any) => {
    return Joi.object().keys({
      name: Joi.string().required().label(i18n.t('labels.name')),
    });
  };

  const generalValidation = (input: FormProps, name?: string): string | undefined | null | { [key: string]: any } => {
    const results = getSchema(i18n).validate(input, { abortEarly: false });

    if (results.error && name) {
      const error = results.error.details.find(obj => obj.path[0] === name);
      return error?.message;
    }

    if (results.error && !name) {
      let errorsObj: { [key: string]: any } | null = null;
      results.error.details.forEach(detail => {
        errorsObj = {
          ...(errorsObj || {}),
          [detail.path[0]]: detail.message,
        };
      });

      return errorsObj;
    }

    return null;
  };

  const onSave = async () => {
    const errors = generalValidation(formData);
    if (errors && typeof errors === 'object') {
      setFormErrors({
        ...formErrors,
        ...errors,
      });
      return;
    }

    setLoading(true);

    let result;
    if (isEdit) {
      result = await updateTeam(team?.id, {
        name: formData.name,
        users: listOfUsers.map(user => ({
          id: user.id,
        })),
      });
    } else {
      result = await createTeam({
        name: formData.name,
        users: listOfUsers.map(user => ({
          id: user.id,
        })),
      });
    }

    setLoading(false);

    if (result?.errors) {
      setFormErrors({
        ...formErrors,
        ...result.errors,
      });

      return;
    }

    setIsDirtyState(false);
    onClose();
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setIsDirtyState(true);
    const { name, value } = event.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(updatedFormData);
  };

  const handleRemoveUser = (user: User) => {
    setIsDirtyState(true);
    setListOfUsers(prevState => prevState.filter(userItem => userItem.id !== user.id));
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      sx={{
        width: '600px',
        alignItems: 'flex-start',
        flexDirection: 'column',
        padding: '32px',
        maxHeight: '80vh',
        overflow: 'hidden',
      }}
    >
      <Stack width={'100%'} justifyContent={'flex-start'} gap={'4px'}>
        <Flex sx={{ justifyContent: 'space-between', width: '100%' }}>
          <Typography variant={'body1'} sx={{ fontWeight: 600 }}>
            {isEdit ? i18n.t('teamsList.button.edit') : i18n.t('teamsList.button.add')}
          </Typography>
          <IconButton size={'small'} onClick={onClose}>
            <Close fontSize={'small'} />
          </IconButton>
        </Flex>
        <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_8 }}>
          {isEdit ? `You are now editing the "${team?.name} team."` : i18n.t('createTeam.create.infoMessage')}
        </Typography>
      </Stack>

      <Divider sx={{ marginTop: '24px', backgroundColor: GRAY_COLORS.GRAY_2 }} />

      <Flex sx={{ alignItems: 'center', gap: '8px', marginTop: '24px' }}>
        <FolderOutlined sx={{ fill: GRAY_COLORS.GRAY_5 }} />
        <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_9 }}>
          {i18n.t('teamsList.table.header.name')}
        </Typography>
      </Flex>

      <TextInput
        id="name"
        name={'name'}
        value={formData.name}
        error={i18n.t(formErrors.name)}
        onChange={onChange}
        variant={'outlined'}
        type={'fullName'}
        testId={'team-name'}
        sx={{ marginTop: '12px' }}
      />

      <Flex sx={{ alignItems: 'center', gap: '8px', marginTop: '24px' }}>
        <People sx={{ fill: GRAY_COLORS.GRAY_5 }} />
        <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_9 }}>
          {i18n.t('teamList.details.members')}
        </Typography>
      </Flex>

      <Box sx={{ marginTop: '12px', width: '100%', marginBottom: '12px' }}>
        <UserAutocomplete
          options={users}
          onChange={value => {
            setIsDirtyState(true);
            setListOfUsers(value);
          }}
          value={listOfUsers}
        />
      </Box>

      <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        {listOfUsers.map(user => (
          <TeamUserRow key={`team-user-${user.id}`} teamUser={user} onRemoveClick={handleRemoveUser} />
        ))}
      </Box>

      <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'} marginTop={'24px'} width={'100%'}>
        <Button
          onClick={onClose}
          variant={'outlined'}
          sx={{
            width: 'fit-content',
            padding: '9px 16px',
            height: '40px',
            marginRight: '12px',
            color: COLOR_PRIMARY,
            borderColor: COLOR_PRIMARY,
          }}
        >
          {i18n.t('common.cancel.action')}
        </Button>
        <Button
          onClick={onSave}
          sx={{ width: 'fit-content', padding: '9px 16px', height: '40px' }}
          disabled={isEdit && !isDirtyState}
          loading={loading}
        >
          {isEdit ? i18n.t('common.saveChanges.action') : i18n.t('createTeam.create.action')}
        </Button>
      </Stack>
    </CustomModal>
  );
};

export default observer(CreateTeamModal);
