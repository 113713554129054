import DropBoxLogo from '../../../assets/icons/integrations/DropBoxLogo.svg';
import GoogleLogo from '../../../assets/icons/integrations/GoogleLogo.svg';
import Office365Logo from '../../../assets/icons/integrations/Office365Logo.svg';
import OktaLogo from '../../../assets/icons/integrations/OktaLogo.svg';
import OneLoginLogo from '../../../assets/icons/integrations/OneLoginLogo.svg';
import SharePointLogo from '../../../assets/icons/integrations/SharePointLogo.svg';
import SplunkLogo from '../../../assets/icons/integrations/SplunkLogo.svg';

export const getIntegrationLogo = (serviceName?: string) => {
  switch (serviceName) {
    case 'splunk':
      return { src: SplunkLogo, alt: 'splunk-logo' };
    case 'google_workspace':
      return { src: GoogleLogo, alt: 'google-workspace-logo' };
    case 'okta':
      return { src: OktaLogo, alt: 'okta-logo' };
    case 'one_login':
      return { src: OneLoginLogo, alt: 'one-login-logo' };
    case 'office_365':
      return { src: Office365Logo, alt: 'office-365-logo' };
    case 'share_point':
      return { src: SharePointLogo, alt: 'share-point-logo' };
    case 'drop_box':
      return { src: DropBoxLogo, alt: 'drop-box-logo' };
    default:
      return null;
  }
};
