import React from 'react';
import { MenuItem, Select } from '@mui/material';
import Typography from '@mui/material/Typography';

import { filterValues } from '../../../constants/adminPortal/insights/filter';

const FilterComponent = ({ id, setFilter, value }: { id: string; setFilter: (arg: any) => void; value: string }) => {
  const onChange = (event: any) => {
    setFilter(event.target.value);
  };

  return (
    <Select labelId={id} id={id} value={value} onChange={onChange}>
      {filterValues?.map((filter, index) => (
        <MenuItem value={filter.value} key={`filter-${index}`}>
          <Typography variant={'subtitle2'}>{filter.name}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default FilterComponent;
