import React, { SyntheticEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';

import { COLOR_BLACK, GRAY_COLORS } from '../../../constants/colors';

import { useStore } from '../../../hooks/useStore';
import GenericCard from '../../cards/GenericCard';
import { Headline3 } from '../../typography/Headlines';
import { PromptByConversation, TopPromptByTeam } from '../../../models/Prompt';
import FlexRowSpaceBetween from '../../utils/flex/FlexRowSpaceBetween';
import ProgressBar from '../../ProgressBar';
import FilterComponent from './FilterComponent';
import { handleTeamColors } from '../../teamTag/TeamTag';
import TabPanel from '../../tabs/TabPanel';

const PromptsTab = ({
  topTeamPrompts,
  maxCountTeam,
}: {
  topTeamPrompts?: Array<TopPromptByTeam>;
  maxCountTeam: number;
}) => (
  <>
    {Array.isArray(topTeamPrompts) &&
      topTeamPrompts.map((item: TopPromptByTeam, index) => (
        <FlexRowSpaceBetween key={`prompt-by-team-${index}`} sx={{ marginTop: '16px' }}>
          <Typography
            variant={'subtitle2'}
            color={COLOR_BLACK}
            fontWeight={500}
            sx={{ width: '100px', minWidth: '100px' }}
          >
            {item.name}
          </Typography>
          <ProgressBar
            barColor={'transparent'}
            progressColor={handleTeamColors(item.name)?.backgroundColor}
            precentage={(100 * item.totalPrompts) / maxCountTeam}
          />
          <Typography variant={'subtitle2'} color={GRAY_COLORS.GRAY_500}>
            {item.totalPrompts}
          </Typography>
        </FlexRowSpaceBetween>
      ))}
  </>
);

const ConversationsTab = ({
  conversationPrompts,
  maxCountConversation,
}: {
  conversationPrompts?: Array<PromptByConversation>;
  maxCountConversation: number;
}) => (
  <>
    {Array.isArray(conversationPrompts) &&
      conversationPrompts.map((item: PromptByConversation, index) => (
        <FlexRowSpaceBetween key={`prompt-by-conversation-${index}`} sx={{ marginTop: '16px' }}>
          <Typography
            variant={'subtitle2'}
            color={COLOR_BLACK}
            fontWeight={500}
            sx={{ width: '100px', minWidth: '100px' }}
          >
            {item.name}
          </Typography>
          <ProgressBar
            barColor={'transparent'}
            progressColor={handleTeamColors(item.name)?.backgroundColor}
            precentage={(100 * item.conversationsCount) / maxCountConversation}
          />
          <Typography variant={'subtitle2'} color={GRAY_COLORS.GRAY_500}>
            {item.conversationsCount}
          </Typography>
        </FlexRowSpaceBetween>
      ))}
  </>
);
const TopPromptsCard = () => {
  const {
    localizationStore: { i18next: i18n },
    insightsStore: { topTeamPrompts, getTopPromptsByTeam, conversationPrompts, getPromptsByConversation },
  } = useStore();

  const [selectedFilter, setSelectedFilter] = useState<string>('allTime');
  const [maxCountTeam, setmaxCountTeam] = useState<number>(0);
  const [maxCountConversation, setmaxCountConversation] = useState<number>(0);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    (async () => {
      await getTopPromptsByTeam(selectedFilter);
      await getPromptsByConversation(selectedFilter);
    })();
  }, [selectedFilter]);

  useEffect(() => {
    if (topTeamPrompts?.length) {
      const promptCounts = topTeamPrompts?.map(item => item.totalPrompts);
      const maxCountTeam = Math.max.apply(Math, promptCounts);
      setmaxCountTeam(maxCountTeam);
    }
  }, [topTeamPrompts]);

  useEffect(() => {
    if (conversationPrompts?.length) {
      const promptCounts = conversationPrompts?.map(item => item.conversationsCount);
      const maxCountConversation = Math.max.apply(Math, promptCounts);
      setmaxCountConversation(maxCountConversation);
    }
  }, [conversationPrompts]);

  const tabs = [
    {
      title: i18n.t('adminPortal.insights.tabs.prompts'),
      component: <PromptsTab topTeamPrompts={topTeamPrompts} maxCountTeam={maxCountTeam} />,
    },
    {
      title: i18n.t('adminPortal.insights.tabs.conversations'),
      component: (
        <ConversationsTab conversationPrompts={conversationPrompts} maxCountConversation={maxCountConversation} />
      ),
    },
  ];

  const changeTab = (event: SyntheticEvent<Element, Event>, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <GenericCard disableHover sx={{ overflow: 'scroll', position: 'relative' }}>
      <Box>
        <FlexRowSpaceBetween>
          <Headline3>{i18n.t('adminPortal.insights.teamStats.subtitle')}</Headline3>
          <FilterComponent id={'top-prompts-filter'} setFilter={setSelectedFilter} value={selectedFilter} />
        </FlexRowSpaceBetween>

        <Tabs value={activeTab} onChange={changeTab} variant="scrollable">
          {tabs.map((tab: { title: string; component: React.ReactNode }, index: number) => (
            <Tab
              key={`admin-insights-tab-${index}`}
              label={tab.title}
              id={`admin-insights-tab-${index}`}
              aria-controls={`admin-insights-tab-panel-${index}`}
            />
          ))}
        </Tabs>
      </Box>

      {tabs.map((tab: { title: string; component: React.ReactNode }, index: number) => (
        <TabPanel key={`tab_insights_${index}`} value={activeTab} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </GenericCard>
  );
};

export default observer(TopPromptsCard);
