import React from 'react';
import { observer } from 'mobx-react';

import SummaryCard from '../../components/adminPortal/insights/SummaryCard';
import Flex from '../../components/utils/flex/Flex';
import TopPromptsCard from '../../components/adminPortal/insights/TopPromptsCard';
import Top10PromptsCard from '../../components/adminPortal/insights/Top10PromptsCard';
import FlexColumnStart from '../../components/utils/flex/FlexColumnStart';

const InsightsPage = () => {
  return (
    <Flex sx={{ height: '100%' }}>
      <FlexColumnStart sx={{ flex: 1, marginRight: '24px', height: 'fit-content', maxHeight: '100%' }}>
        <SummaryCard />
        <TopPromptsCard />
      </FlexColumnStart>

      <Top10PromptsCard />
    </Flex>
  );
};

export default observer(InsightsPage);
