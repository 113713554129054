import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CategoryContainer } from '../../components/adminPortal/integrations/IntegrationCard';
import { getIntegrationLogo } from '../../components/adminPortal/integrations/IntegrationLogo';
import Button from '../../components/buttons/Button';
import TextInput from '../../components/inputs/TextInput';
import { PrimarySwitch } from '../../components/Switch/PrimarySwitch';
import InfoTooltip from '../../components/tooltip/InfoTooltip';
import { Headline3 } from '../../components/typography/Headlines';
import Flex from '../../components/utils/flex/Flex';
import FlexRowStart from '../../components/utils/flex/FlexRowStart';

import { GRAY_COLORS } from '../../constants/colors';

import { useStore } from '../../hooks/useStore';

const Logo = styled.img`
  margin-right: 12px;
  max-height: 32px;
`;

interface ConfigProps {
  handleIsActiveChange: (id: string, isActive: boolean) => void;
  handleSaveChanges: () => void;
  handleUpdateConfig: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}
const ConfigureIntegrationPage = ({ handleIsActiveChange, handleSaveChanges, handleUpdateConfig }: ConfigProps) => {
  const {
    integrationsStore: { selectedIntegration, setSelectedIntegration },
    localizationStore: { i18next: i18n },
  } = useStore();
  const timeout = useRef(null);

  const [disabledButton, setDisabledButton] = useState(true);
  const isActiveDisabled =
    !selectedIntegration?.settings?.configuration?.configName ||
    !selectedIntegration?.settings?.configuration?.url ||
    !selectedIntegration?.settings?.configuration?.token;
  const [isShowing, setIsShowing] = useState(isActiveDisabled);

  useEffect(() => {
    // @ts-ignore
    timeout.current = setTimeout(() => setIsShowing(false), 3000);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };
  }, []);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setDisabledButton(false);
    handleUpdateConfig(event);
  };

  const handleActive = (isActive: boolean) => {
    setDisabledButton(false);
    setSelectedIntegration({ ...selectedIntegration, isActive });
    handleIsActiveChange(selectedIntegration.id, isActive);
  };

  if (!selectedIntegration) {
    return null;
  }

  const integrationLogo = getIntegrationLogo(selectedIntegration?.serviceName);

  return (
    <>
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        {selectedIntegration.tags ? <CategoryContainer>{selectedIntegration.tags}</CategoryContainer> : <div></div>}
        <Flex>
          <Button
            onClick={() => {
              window.open('https://avm-public-docs.s3.amazonaws.com/Splunk+Cloud.pdf', '_blank');
            }}
            variant={'outlined'}
            sx={{ marginRight: '16px' }}
            startIcon={<OpenInNewIcon />}
          >
            {i18n.t('documentation.button.action')}
          </Button>
          <Button onClick={handleSaveChanges} disabled={disabledButton}>
            {i18n.t('common.saveChanges.action')}
          </Button>
        </Flex>
      </Flex>
      <FlexRowStart>
        {integrationLogo ? <Logo src={integrationLogo.src} alt={integrationLogo.alt} /> : <div></div>}
        <Headline3>{selectedIntegration.name}</Headline3>
        <InfoTooltip
          title={i18n.t('adminPortal.integrations.tooltip.switchActive')}
          open={isShowing && isActiveDisabled}
        >
          <PrimarySwitch
            disabled={isActiveDisabled}
            checked={selectedIntegration.isActive}
            onChange={event => handleActive(event.target.checked)}
          />
        </InfoTooltip>
      </FlexRowStart>
      <Typography variant={'body2'} sx={{ marginTop: '12px', marginBottom: '24px', color: GRAY_COLORS.GRAY_8 }}>
        {selectedIntegration.description}
      </Typography>

      <Typography variant={'body2'} sx={{ fontWeight: '500' }}>
        {i18n.t('adminPortal.integrations.label.splunk.name')}
      </Typography>
      <TextInput
        id="integration-configName"
        name={'configName'}
        value={selectedIntegration?.settings?.configuration?.configName}
        onChange={onChange}
        variant={'outlined'}
        type={'text'}
        sx={{ marginTop: '4px', height: '40px', marginBottom: '16px' }}
      />

      <Typography variant={'body2'} sx={{ fontWeight: '500' }}>
        {i18n.t('adminPortal.integrations.label.splunk.url')}
      </Typography>
      <TextInput
        id="integration-url"
        name={'url'}
        placeholder={'https://'}
        value={selectedIntegration?.settings?.configuration?.url}
        onChange={onChange}
        variant={'outlined'}
        type={'text'}
        sx={{ marginTop: '4px', height: '40px', marginBottom: '16px' }}
      />

      <Typography variant={'body2'} sx={{ fontWeight: '500' }}>
        {i18n.t('adminPortal.integrations.label.splunk.token')}
      </Typography>
      <TextInput
        id="integration-token"
        name={'token'}
        value={selectedIntegration?.settings?.configuration?.token}
        onChange={onChange}
        variant={'outlined'}
        type={'text'}
        sx={{ marginTop: '4px', height: '40px' }}
      />
    </>
  );
};

export default observer(ConfigureIntegrationPage);
