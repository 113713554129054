import React from 'react';
import Menu from '@mui/material/Menu';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { styled } from '@mui/material/styles';

import { COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';
import { Paths } from '../../constants/routes';

import Profile from '../../assets/icons/menuIcons/Profile.svg';
import Home from '../../assets/icons/menuIcons/Home.svg';

import UserAvatar from '../UserAvatar/UserAvatar';
import { useStore } from '../../hooks/useStore';

interface UserMenuProps {
  anchorElement: Element | undefined | null | ((element: Element) => Element);
  handleCloseMenu: () => void;
}

const ItemsContainer = styled(Box)`
  background: ${COLOR_WHITE};
  border-radius: 8px;
  margin: 8px;
`;

const UserMenu = ({ anchorElement, handleCloseMenu }: UserMenuProps) => {
  const {
    authStore: { logout },
    userStore: { userData },
    localizationStore,
  } = useStore();
  const { i18next: i18n } = localizationStore;
  const navigate = useNavigate();

  let chatPortalLink = '';
  const originUrl = window.location.origin;
  if (originUrl.includes('admin.')) {
    chatPortalLink = originUrl.replace('admin.', 'chat.');
  }

  return (
    <Menu
      anchorEl={anchorElement as any}
      onClose={handleCloseMenu}
      id="user-menu-appbar"
      className="user-menu-appbar"
      open={Boolean(anchorElement)}
      PaperProps={{
        style: {
          width: '270px',
          borderRadius: '8px',
        },
      }}
    >
      <ItemsContainer>
        <Box display={'flex'} alignItems={'center'} marginBottom={'16px'} sx={{ padding: '6px 16px' }}>
          <UserAvatar user={userData} />
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }}>
            <Typography variant={'body1'}>{userData.fullName}</Typography>
          </Box>
        </Box>
        {chatPortalLink && (
          <MenuItem
            sx={{
              marginTop: '8px',
              height: '56px',
              borderRadius: '8px',
            }}
            onClick={() => {
              window.location.href = chatPortalLink;
              handleCloseMenu();
            }}
          >
            <img src={Home} alt={'home-icon'} />
            <Typography variant={'body2'} sx={{ marginLeft: '24px' }}>
              {i18n.t('sidebarMenu.profile.chat')}
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          sx={{
            marginTop: '8px',
            height: '56px',
            borderRadius: '8px',
          }}
          onClick={() => {
            navigate(Paths.PROFILE);
            handleCloseMenu();
          }}
        >
          <img src={Profile} alt={'profile-icon'} />
          <Typography variant={'body2'} sx={{ marginLeft: '24px' }}>
            {i18n.t('sidebarMenu.profile.action')}
          </Typography>
        </MenuItem>

        <MenuItem
          sx={{
            marginTop: '8px',
            height: '56px',
            borderRadius: '8px',
          }}
          onClick={async () => {
            await logout();
            navigate(Paths.LOGIN);
          }}
        >
          <ExitToApp sx={{ fill: GRAY_COLORS.GRAY_500 }} />
          <Typography variant={'body2'} sx={{ marginLeft: '24px' }}>
            {i18n.t('appHeader.logout.action')}
          </Typography>
        </MenuItem>
      </ItemsContainer>
    </Menu>
  );
};

export default observer(UserMenu);
