export const filterValues = [
  {
    value: 'allTime',
    name: 'All time',
  },
  {
    value: 'thisMonth',
    name: 'This month',
  },
  {
    value: 'lastMonth',
    name: 'Last month',
  },
  {
    value: 'thisYear',
    name: 'This year',
  },
  {
    value: 'lastYear',
    name: 'Last year',
  },
];
