import { UserFromGetModel } from '../models/UserModel';

export const SortUserFormulas = {
  fromAtoZ: (users: UserFromGetModel[]) =>
    users?.slice()?.sort((user1, user2) => user1?.fullName?.localeCompare(user2.fullName)),
  fromZtoA: (users: UserFromGetModel[]) =>
    users?.slice()?.sort((user1, user2) => user2?.fullName?.localeCompare(user1.fullName)),
  leastToMostActive: (users: UserFromGetModel[]) => users?.sort((a, b) => (a.totalChats || 0) - (b.totalChats || 0)),
  mostToLeastActive: (users: UserFromGetModel[]) => users?.sort((a, b) => (b.totalChats || 0) - (a.totalChats || 0)),
};
