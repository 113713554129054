import React, { useState, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

import { COLOR_WHITE } from '../../constants/colors';
import { SIZES_SMALL } from '../../constants/sizes';

import TeamsPage from './TeamsPage';
import UsersPage from './UsersPage';
import { useStore } from '../../hooks/useStore';
import TabPanel from '../../components/tabs/TabPanel';
import { Headline1 } from '../../components/typography/Headlines';
import FullHeightScreenWrapper from '../../components/FullHeightComponentWrapper/FullHeightScreenWrapper';
import AuditLogPage from './AuditLogPage';
import InsightsPage from './InsightsPage';
import CompliancePage from './CompliancePage';
import ModelsPage from './ModelsPage/ModelsPage';
import IntegrationsPage from './IntegrationsPage';

const ScreenWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 56px);

  position: relative;
  overflow: hidden;

  @media screen and (max-width: ${SIZES_SMALL}) {
    overflow-x: hidden;
  }
`;

interface AdminPortalTab {
  title: string;
  component: React.ReactNode;
  displayTitle: boolean;
  index: number;
  lazyLoad?: boolean;
}

const AdminPortalPage = () => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();
  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (event: SyntheticEvent<Element, Event>, newValue: number) => {
    setActiveTab(newValue);
  };

  const tabs: AdminPortalTab[] = [
    {
      title: i18n.t('adminPortal.tabs.insights.action'),
      component: <InsightsPage />,
      displayTitle: false,
      index: 0,
    },
    {
      title: i18n.t('adminPortal.tabs.teams.action'),
      component: <TeamsPage activeTab={activeTab} changeTab={changeTab} />,
      displayTitle: false,
      index: 1,
    },
    {
      title: i18n.t('adminPortal.tabs.users.action'),
      component: <UsersPage adminActiveTab={activeTab} />,
      displayTitle: false,
      index: 2,
    },
    {
      title: i18n.t('adminPortal.tabs.logs.action'),
      component: <AuditLogPage activeTab={activeTab} />,
      displayTitle: false,
      index: 3,
    },
    {
      title: i18n.t('adminPortal.tabs.models.action'),
      component: <ModelsPage />,
      displayTitle: false,
      index: 4,
      lazyLoad: true,
    },
    {
      title: i18n.t('adminPortal.tabs.compliance.action'),
      component: <CompliancePage activeTab={activeTab} setActiveTab={setActiveTab} />,
      displayTitle: false,
      index: 5,
    },
    {
      title: i18n.t('adminPortal.tabs.integrations.action'),
      component: <IntegrationsPage activeTab={activeTab} />,
      displayTitle: false,
      index: 6,
    },
  ];

  const renderTab = (tab: AdminPortalTab) => {
    if (tab.lazyLoad) {
      if (activeTab === tab.index) {
        return (
          <FullHeightScreenWrapper sx={{ backgroundColor: 'transparent' }}>
            {tab.displayTitle && <Headline1>{tab.title}</Headline1>}
            {tab.component}
          </FullHeightScreenWrapper>
        );
      }

      return <></>;
    }

    return (
      <FullHeightScreenWrapper sx={{ backgroundColor: 'transparent' }}>
        {tab.displayTitle && <Headline1>{tab.title}</Headline1>}
        {tab.component}
      </FullHeightScreenWrapper>
    );
  };

  return (
    <ScreenWrapper>
      <Tabs
        value={activeTab}
        onChange={changeTab}
        variant="scrollable"
        sx={{
          padding: '0 40px',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1000,
          background: COLOR_WHITE,
          width: '100%',
        }}
      >
        {tabs.map((tab, index: number) => (
          <Tab
            key={`admin-portal-tab-${index}`}
            label={tab.title}
            id={`admin-portal-tab-${index}`}
            aria-controls={`admin-portal-tab-panel-${index}`}
          />
        ))}
      </Tabs>

      {tabs.map((tab, index: number) => (
        <TabPanel key={`tab_${index}`} value={activeTab} index={index} sx={{ marginTop: '49px' }}>
          {renderTab(tab)}
        </TabPanel>
      ))}
    </ScreenWrapper>
  );
};

export default observer(AdminPortalPage);
