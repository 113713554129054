export enum SupportedAIProvidersEnum {
  open_ai = 'open_ai',
  azure = 'azure',
  google = 'google',
  cohere = 'cohere',
  amazon = 'amazon',
  anthropic = 'anthropic',
  ai21Labs = 'ai21Labs',
  renaissance = 'renaissance',
}

export const SupportedAIProvidersOptions = [
  {
    id: SupportedAIProvidersEnum.open_ai,
    name: 'providers.open_ai',
  },
  {
    id: SupportedAIProvidersEnum.azure,
    name: 'providers.azure',
  },
  {
    id: SupportedAIProvidersEnum.google,
    name: 'providers.google',
  },
  {
    id: SupportedAIProvidersEnum.cohere,
    name: 'providers.cohere',
  },
  {
    id: SupportedAIProvidersEnum.amazon,
    name: 'providers.amazon',
  },
  {
    id: SupportedAIProvidersEnum.anthropic,
    name: 'providers.anthropic',
  },
  {
    id: SupportedAIProvidersEnum.ai21Labs,
    name: 'providers.ai21Labs',
  },
  {
    id: SupportedAIProvidersEnum.renaissance,
    name: 'providers.renaissance',
  },
];
