import React from 'react';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import HistoryIcon from '@mui/icons-material/History';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

import { COLOR_PRIMARY, GRAY_COLORS } from '../../../constants/colors';

import { useStore } from '../../../hooks/useStore';
import FlexRowCenter from '../../utils/flex/FlexRowCenter';

const containerStyle = {
  gap: '8px',
  cursor: 'pointer',
};

const ActionButton = ({
  archived,
  isPending,
  onClick,
  applyBorders = false,
}: {
  archived: boolean;
  isPending: boolean;
  applyBorders?: boolean;
  onClick: () => void;
}) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  let actionName;
  let Icon;

  if (archived) {
    actionName = i18n.t('menuPopUp.activate.action');
    Icon = HistoryIcon;
  } else {
    if (isPending) {
      actionName = i18n.t('common.cancel.action');
      Icon = CloseIcon;
    } else {
      actionName = i18n.t('menuPopUp.disable.action');
      Icon = RemoveCircleOutlineOutlinedIcon;
    }
  }

  return (
    <FlexRowCenter
      sx={
        applyBorders
          ? { ...containerStyle, border: `1px solid ${GRAY_COLORS.GRAY_2}`, padding: '7px 12px', borderRadius: '4px' }
          : containerStyle
      }
      onClick={onClick}
    >
      <Icon sx={{ width: '24px', height: '24px', color: COLOR_PRIMARY }} />
      <Typography variant={'subtitle2'} color={GRAY_COLORS.GRAY_9} fontWeight={500}>
        {actionName}
      </Typography>
    </FlexRowCenter>
  );
};

export default observer(ActionButton);
