import React, { useState } from 'react';
import { Autocomplete, Button, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { COLOR_WHITE } from '../../constants/colors';

import Flex from '../utils/flex/Flex';
import CustomModal from './CustomModal';
import { OrganizationModel } from '../../models/OrganizationModel';
import { useStore } from '../../hooks/useStore';

interface AddDefaultModelModalProps {
  headerText: string;
  isOpen: boolean;
  onClose: () => void;
  options: OrganizationModel[];
}

const AddDefaultModelModal = ({ isOpen, onClose, options, headerText }: AddDefaultModelModalProps) => {
  const [defaultModel, setDefaultModel] = useState<OrganizationModel>();

  const {
    localizationStore: { i18next: i18n },
    modelStore: { updateOrganizationModel, defaultOrganizationModel },
  } = useStore();

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      sx={{ display: 'flex', borderRadius: '8px', width: '600px', padding: '24px' }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          flex: 1,
          backgroundColor: COLOR_WHITE,
          padding: '24px',
        }}
      >
        <Typography variant={'h5'} sx={{ fontSize: '16px', marginBottom: '8px' }}>
          {i18n.t(headerText)}
        </Typography>
        <Typography variant={'body2'} sx={{ marginBottom: '24px' }}>
          {i18n.t('adminPortal.tabs.models.selectDefaultModel')}
        </Typography>

        <Typography variant={'body2'} sx={{ fontWeight: 600 }}>
          {i18n.t('adminPortal.tabs.models.defaultModel')}
        </Typography>

        <Autocomplete
          value={defaultModel}
          getOptionLabel={(option: any) => option.displayName}
          renderInput={({ inputProps, ...rest }) => {
            return <AutoCompleteTextField {...rest} inputProps={{ ...inputProps }} placeholder={'Select'} />;
          }}
          options={options}
          onChange={(event, value) => setDefaultModel(value || undefined)}
        />

        <Flex sx={{ marginTop: '40px', justifyContent: 'flex-end' }}>
          <Box>
            <Button variant={'outlined'} onClick={onClose}>
              {i18n.t('common.cancel.action')}
            </Button>
          </Box>
          <Box sx={{ marginLeft: '16px' }}>
            <Button
              variant={'contained'}
              onClick={() => {
                if (headerText === 'modelsPage.modal.header.cantDisableDefaultModel') {
                  updateOrganizationModel({ ...defaultOrganizationModel, isDefault: false, isActive: false });
                }

                if (defaultModel) {
                  updateOrganizationModel({ ...defaultModel, isDefault: true, isActive: true });
                }

                onClose();
              }}
            >
              {i18n.t('adminPortal.tabs.models.setAsDefault.action')}
            </Button>
          </Box>
        </Flex>
      </Flex>
    </CustomModal>
  );
};

const AutoCompleteTextField = styled(TextField)`
  & .MuiInputBase-root {
    height: unset;
  }
  & .MuiInputLabel-root {
    top: 0
  },
  & .MuiInputLabel-shrink {
    top: 0
  },
`;

export default AddDefaultModelModal;
